<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Lista de Categorias do Blog</h4>

          <div class="page-title-right">
            <b-button
              variant="success"
              @click.prevent="$router.push({ name: 'blog-categoria' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12">
        <div class="card">
          <div
            class="card-header bg-white border-bottom d-flex align-items-center justify-content-between"
          >
            <h5 class="card-title">
              <i class="fas fa-filter text-dark mr-2"></i>
              Filtro
            </h5>
            <b-button
              variant="light"
              @click.prevent="limparFiltro()"
              v-b-tooltip.hover
              title="Limpar Filtro"
            >
              <i class="bx bx-sync font-size-16 align-middle"></i>
            </b-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Nome</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome da categoria"
                    for="text"
                    v-model="filtroCategory.search"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Status</label>
                <multiselect
                  v-model="status"
                  :options="options_status"
                  placeholder="Status"
                  class="helo"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  label="text"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2 mt-lg-0">
                <label>Categoria Principal</label>
                <multiselect
                  v-model="categorys_parent"
                  placeholder="Categoria Principal"
                  :options="options_categorys"
                  class="helo"
                  label="name"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoading"
                  @search-change="searchCategory"
                ></multiselect>
              </div>
            </div>
            <div class="row" v-if="loading">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else-if="error">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="text-center text-danger my-2">
                      <h5 class="mb-3">Erro ao carregar dados!</h5>
                      <img width="250" src="@/assets/images/error-img.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else-if="tableData.length <= 0">
              <div class="col-12">
                <div class="card">
                  <div class="card-body py-5 text-center">
                    <h5 class="mb-3">Nenhum item encontrado!</h5>
                    <img width="250" src="@/assets/images/not-data.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5 border-top" v-else>
              <b-table
                :items="tableData"
                :fields="fields"
                responsive
                caption-top
                hover
                head-variant="light"
              >
                <template #cell(image)="data">
                  <div v-if="data.item.image != null && data.item.image != ''">
                    <span v-for="img in data.item.image" :key="img.id">
                      <img
                        v-if="img.capa == 1"
                        :src="img.image300"
                        alt=""
                        class="avatar-md h-auto d-block rounded"
                        loading="lazy"
                      />
                    </span>
                  </div>
                  <img
                    v-else
                    src="@/assets/images/notpro.jpg"
                    alt=""
                    class="avatar-md h-auto d-block rounded"
                    loading="lazy"
                  />
                </template>
                <template #cell(action)="data">
                  <a
                    href="#"
                    @click.prevent="
                      $router.push({
                        name: 'blog-categoria-edit',
                        params: { id: data.item.id },
                      })
                    "
                  >
                    <i class="fas fa-pencil-alt text-success"></i>
                  </a>
                  <a
                    href="#"
                    @click.prevent="removerItem(data.index, data.item)"
                  >
                    <i class="fas fa-trash-alt text-danger ml-4"></i>
                  </a>
                </template>
                <template #cell(status)="data">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class="{
                      'badge-soft-success': `${data.item.status}` == 0,
                      'badge-soft-danger': `${data.item.status}` == 1,
                    }"
                  >
                    {{ data.item.status == 0 ? 'Ativa' : 'Bloqueada' }}
                  </span>
                </template>
                <template #cell(created_at)="data">
                  {{ data.item.created_at | moment('DD/MM/YYYY') }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="row pb-5" v-if="loadingbtn">
      <div class="col-12 text-center">
        <b-button variant="info" @click.prevent="carregarMais()">
          <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
          Carregar Mais
        </b-button>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Multiselect,
    InfiniteLoading,
  },
  watch: {
    'filtroCategory.search': function () {
      this.listCategory()
    },
    status: function () {
      if (this.status != null) {
        this.filtroCategory.status = this.status.value
        this.listCategory()
      }
    },
    categorys_parent: function () {
      if (this.categorys_parent != null) {
        this.filtroCategory.category_main_id = this.categorys_parent.id
        this.listCategory()
      }
    },
  },
  data() {
    return {
      categorys_parent: null,
      status: null,
      options_status: [
        { text: 'Ativa', value: 0 },
        { text: 'Bloqueada', value: 1 },
      ],
      options_categorys: [],
      filtro: {
        limit: 10,
        page: 1,
      },
      filtroCategory: {
        limit: 10,
        page: 1,
        order: "created_at"
      },
      isLoading: false,
      sortBy: 'created_at',
      sortDesc: false,
      fields: [
        {
          key: 'image',
          label: 'Imagem',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Ação',
          sortable: false,
        },
      ],
      tableData: [],
      loading: true,
      error: false,
      loadingbtn: true,
    }
  },
  created() {
    this.listCategorySelect()
  },
  methods: {
    ...mapActions('blog', ['ActionCategoryBlogAll', 'ActionCategoryBlogUpdate']),
     async carregarMais() {
      try {
        this.loadingbtn = true
        await this.ActionCategoryBlogAll(this.filtroCategory).then((res) => {
          if (res.data.data.list.length) {
            this.filtroCategory.page += 1
            this.tableData.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.tableData = []
        this.loading = false
        this.error = true
      }
    },
    async infiniteHandler($state) {
      try {
        this.loadingbtn = true
        await this.ActionCategoryBlogAll(this.filtroCategory).then((res) => {
          if (res.data.data.list.length) {
            this.filtroCategory.page += 1
            this.tableData.push(...res.data.data.list)
            $state.loaded()
          } else {
             this.loadingbtn = false
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.tableData = []
        $state.complete()
      }
    },
    async listCategory() {
      try {
        this.filtroCategory.page = 1
        this.loadingbtn = true
        await this.ActionCategoryBlogAll(this.filtroCategory).then((res) => {
          if (res.data.data.list.length) {
            this.filtroCategory.page = 2
            this.tableData = res.data.data.list
          } else {
            this.loadingbtn = false
            this.tableData = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.tableData = []
      }
    },
    async listCategorySelect() {
      try {
        this.isLoading = true
        await this.ActionCategoryBlogAll(this.filtro).then((res) => {
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtro.search = e
      this.listCategorySelect()
    },
    limparFiltro() {
      this.filtroCategory = {
        limit: 10,
        page: 1,
      }
      this.loadingbtn = true
      this.options_categorys = []
      this.listCategory()
    },
    removerItem(index, data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(index, data)
        }
      })
    },
    async removerItemUp(index, data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionCategoryBlogUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.tableData.splice(this.tableData.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
